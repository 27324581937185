import classnames from 'classnames';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { withSSR } from 'react-i18next';
import { Provider } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import './App.css';
import './custom.scss';
import './polyfills';
import { onCLS, onLCP, onINP, onFCP, onTTFB } from 'web-vitals';

// Error Handling and Routing Components
import { ErrorBoundary } from './client/atoms/ErrorBoundary';
import { RouterWrapper } from './client/atoms/RouterWrapper';

// Models and Configuration
import { ActionTypes, CustomCategories } from './client/models/Analytics';
import { ArenaConfig } from './client/models/ArenaConfig';
import { StoreModifiers } from './client/models/StoreModifiers';
import { LocalStorageSKeys } from './client/services/AuthService';
import { LocalStorageService } from './client/services/LocalStorage';
import { KeysEnum } from './client/models/Enums';
import { TMetric, TWebVitals } from './client/models/TWebVitals';

// Services
import adsService from './client/services/AdsService';
import { ABTestManager } from './client/services/ABTests/ABTestManager';
import { ABTestProvider } from './client/services/ABTests/ABTestReact';
import { ABTestService } from './client/services/ABTestService';
import { AnalyticsGeneral } from './client/services/Analytics/AnalyticsGeneral';
import { AnalyticsInit } from './client/services/Analytics/AnalyticsInit';
import { DeviceDetector } from './client/services/DeviceDetector';
import { DisplayAdService } from './client/services/DisplayAdService';
import { PwaService } from './client/services/PwaService';
import { setConfig } from './client/store/ducks/config';
import { GDPRMediatorService } from './client/services/Analytics/GDPR';
import { CookieStorageService } from './client/services/CookieStorage';
import { FingerprintService } from './client/services/Analytics/FingerprintJS/FingerprintService';
import { AppInsightsAnalytics } from './client/services/Analytics/AppInsights';
import { environment } from './client/config/environment';

// UI Components
import { Footer } from './client/organisms/Footer/Footer';
import { HeaderSplitWrapper } from './client/organisms/Header/Redesign/HeaderSplitWrapper';
import { CookieBanner } from './client/molecules/CookieBanner/CookieBanner';
import { Snackbar } from './client/FigmaStyleguide/Snackbar/Snackbar';
import { ShopModal } from './client/molecules/ShopModal/ShopModal';
import { LoginModal } from './client/organisms/Login/LoginModal';
import { ABTestedLightboxFeature } from './client/organisms/LightboxPromotionFeature/ABTestedLightboxPromotionFeature';
import createStore from './client/store/index';
import { AppState } from './client/store/types';
import { defaultNameSpace, i18nClientInit } from './i18n';
import { routes } from './routes';
import { isServer } from './utils';
import { restoreUserIfAuthorizedThunk } from './client/store/ducks/user';

void i18nClientInit();

// Create the store and extract the history object
const { store, history } = createStore();

// Apply global settings from local storage
StoreModifiers.useConfigFromLocalStorage(store);
StoreModifiers.applyGlobalGamesSortings(store);

// Extract configuration and initialize arena
const { abTests, arenaOrigin, texts, currentLang, config } = store.getState() as AppState;
const arenaConfig = new ArenaConfig(config, currentLang);

store.dispatch(setConfig(arenaConfig));

// Activate PWA service worker and initialize ads
PwaService.activateServiceWorker(config);
DisplayAdService.init(config.ad).then(() => adsService.initDisplayAds(config.theme.adRefreshTimeMs));

// Expose store and arena information to the global window object if not on server
if (!isServer) {
    (window as any).STORE = store;
    (window as any).__ARK__ArenaInfo = {
        arenaVersion: '5.1',
        domain: config.theme?.domain,
    };
}

(Provider as any).displayName = 'Provider';

export class App extends React.Component {
    static displayName = 'App';
    protected abtests: ABTestManager;

    constructor(props) {
        super(props);
        const state = store.getState();
        const cookie = CookieStorageService.get(KeysEnum.arkabtests);

        this.abtests = new ABTestManager();
        this.abtests.init(state.config.abTestsSettings, cookie);
        ABTestService.init(abTests);
        const isDevelopmentMode = environment.ENV !== 'live' && environment.ENV !== 'canary';

        // Initialize Analytics and Fingerprint services
        AnalyticsInit.init(config, abTests, state.is404);
        AnalyticsInit.trackPageView(undefined, state.is404);
        AnalyticsInit.trackInconsistentArena(state.arenaOrigin);
        FingerprintService.init(isDevelopmentMode);
    }

    trackWebVitals() {
        const lsKey = 'skipcwv';
        let lsValue = LocalStorageService.getItem(lsKey);
        let skipCWVTracking = false;

        if (lsValue === null) {
            skipCWVTracking = !(Math.random() < 30 / 100); // 30% of users should send the event
            LocalStorageService.setItem(lsKey, skipCWVTracking);
        } else {
            skipCWVTracking = lsValue === 'true';
        }

        if (skipCWVTracking) {
            return;
        }

        const eventName = 'WebVitals';
        let allMetrics: TWebVitals = {};
        let options: Record<string, any> = {};
        const addToMetrics = (metric: TMetric) => {
            allMetrics[metric.name] = { value: metric.value };

            // Collect on page load: FCP, TTFB, LCP
            if (allMetrics.LCP && allMetrics.FCP && allMetrics.TTFB) {
                sendToAnalytics(allMetrics);
            }
        };
        const sendToAnalytics = (metrics: TWebVitals) => {
            Object.keys(metrics).forEach(key => {
                options[key] = JSON.stringify(metrics[key]);
            });
            options['noninteraction'] = (typeof window.userActive === 'boolean' && !window.userActive);
            AppInsightsAnalytics.trackEvent(eventName, options);
            allMetrics = {};
            options = {};
        };

        onLCP(addToMetrics);
        onCLS(metric => this.logMetric(metric, eventName));
        onINP(metric => this.logMetric(metric, eventName));
        onFCP(addToMetrics);
        onTTFB(addToMetrics);
    }

    logMetric(metric: TMetric, eventName: string) {
        AppInsightsAnalytics.trackEvent(eventName, {
            [metric.name]: JSON.stringify({ value: metric.value }),
            'noninteraction': (typeof window.userActive === 'boolean' && !window.userActive)
        });
    }

    componentDidMount() {
        const state = store.getState() as AppState;

        AppInsightsAnalytics.setupPingTracking();

        if (!state.config.isEagle || (state.config.sso?.name === 'usatoday' && state.config.isEagle) ||
            (state.config.isEagle && state.config.sso.name === 'hsn')) {
            store.dispatch<any>(restoreUserIfAuthorizedThunk());
        }

        this.setArenaDataAttributes();

        // Track PWA visits
        this.trackPwaVisits();

        // Track web vitals
        if (!isServer) {
            this.trackWebVitals();
        }
    }

    setArenaDataAttributes() {
        if (!isServer) {
            try {
                const { hostname } = window.location;

                document.documentElement.dataset.arena_origin = document.documentElement.dataset.arena_origin || hostname;
                document.documentElement.dataset.custom_header = document.documentElement.dataset.custom_header || store.getState()?.config?.theme?.client;
            } catch (e) {
                console.error('Error setting arena data attributes: ', e);
            }
        }
    }

    trackPwaVisits() {
        if (PwaService.isIosStandalone()) {
            AnalyticsGeneral.pwa(ActionTypes.IOS_BOOKMARK_VISIT, CustomCategories.IOS_BOOKMARK);
        }

        if (PwaService.isAndroidPcStandalone()) {
            const action = DeviceDetector.isDesktop() ? ActionTypes.VISIT : ActionTypes.ANDROID_BOOKMARK_VISIT;
            const category = DeviceDetector.isDesktop() ? CustomCategories.DESKTOP_PWA : CustomCategories.ANDROID_BOOKMARK;

            AnalyticsGeneral.pwa(action, category);
        }
    }

    render() {
        return (
            <ABTestProvider manager={this.abtests}>
                <HelmetProvider>
                    <Provider store={store}>
                        <ConnectedRouter history={history}>
                            <ErrorBoundary>
                                <RouterWrapper>
                                    <HeaderSplitWrapper store={store} />
                                    <div className={classnames('main-content')}>{renderRoutes(routes())}</div>
                                    <Footer />
                                    <Snackbar />
                                    <ShopModal />
                                    <LoginModal />
                                    <ABTestedLightboxFeature />
                                    <CookieBanner currentLang={currentLang} config={config} />
                                </RouterWrapper>
                            </ErrorBoundary>
                        </ConnectedRouter>
                    </Provider>
                </HelmetProvider>
            </ABTestProvider>
        );
    }
}

// SEO-oriented redirect for SSR-proceeded and served requests of URLs
(function () {
    if (!isServer) {
        const originalUrl = window.location.href;
        const originalPath = window.location.pathname;
        const originalUrlExtras = window.location.search || window.location.hash;
        const isSeoRedirect = originalPath.match(/.+\/$/gi) && !originalUrlExtras;
        const userHasToken = localStorage.getItem(LocalStorageSKeys.apiToken);
        const localeUrlRegexp = new RegExp(`^/${config.theme?.locale || 'en'}(/|$)`, 'gi');
        const isPageLocaleEqualToMainPage = Boolean(originalPath.match(localeUrlRegexp));
        let targetUrl = '';

        // Check if this is SEO ranking competition between main path and locale path with the same language
        if (isPageLocaleEqualToMainPage) {
            targetUrl = window.location.origin + originalPath.replace(localeUrlRegexp, '/');
        }

        if (isSeoRedirect && !userHasToken) {
            targetUrl = targetUrl.replace(/\/$/gi, '');
        }

        if (targetUrl) {
            window.location.assign(targetUrl);
        }
    }
})();

// Render the application
const ExtendedApp = withSSR()(App);
const root = document.getElementById('root');

root.innerHTML = '';
ReactDOM.render(
    <ExtendedApp initialLanguage={currentLang} initialI18nStore={{ [currentLang]: { [defaultNameSpace]: texts } }} />,
    root
);

export default App;
